<ul class="wrapper">
  @for (option of options; track option) {
    <li>
      <app-variant-option
        [option]="option"
        [selected]="option === value"
        [disabled]="disabled"
        [notification]="notification"
        (toggle)="onToggle(option)"
        (notify)="onNotify(option)"
      ></app-variant-option>
    </li>
  }
</ul>
